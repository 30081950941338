export const buildAcuityUrl = (category) => {
  let acuityUri =
    "https://healinggardens.as.me/schedule.php?appointmentType=category:";
  return `${acuityUri}${encodeURI(category)}`;
};

export const resolveUrl = (imageString, imageType) => {
  if (imageString !== null) {
    if (imageString?.includes("http://res.cloudinary.com/healinggardens/")) {
      return imageString;
    }
    switch (
      imageType //type of image
    ) {
      case 1: //profile picture
        return `https://res.cloudinary.com/healinggardens/image/upload/ar_1:1,c_fill,g_auto,q_auto:best,r_max,w_200/${imageString}`;
      case 2: // thumbnail
        return `https://res.cloudinary.com/healinggardens/image/upload/w_600,q_auto:best,f_auto/,c_scale/${imageString}`;
      default:
        // default
        return `https://res.cloudinary.com/healinggardens/image/upload/q_auto:best,f_auto/${imageString}`;
    }
  }
  return null;
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const isInThePast = (date) => {
  if (!date) return;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const GENERIC_ERROR_MESSAGE =
  "Sorry, we were unable to load the widget at this time. This may be due to a network issue or some other error. \nPlease try again later, and if the issue persists, you can contact us at support@healinggardens.co. Thank you for your patience.";
