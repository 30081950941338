import React from "react";
import styled from "styled-components";
import { GENERIC_ERROR_MESSAGE } from "../../common/hg_helper";
import MultiCards from "../Cards/MultiCard";
import Carousel from "react-multi-carousel";
import SkeletonCard from "../Cards/SkeletonCard";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
};
const responsiveForSingleEvent = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const WidgetSliderWraper = ({
  dataValue,
  isSingleEventCard,
  loading,
  error,
  cardData,
}) => {
  const carousel = React.useRef(null);
  return (
    <div
      style={{
        minHeight: "400px",
        paddingTop: "40px",
        overflow: "hidden",
      }}
    >
      <SliderWrapper>
        {loading ? (
          <Carousel
            responsive={
              isSingleEventCard ? responsiveForSingleEvent : responsive
            }
            partialVisible={true}
            renderArrowsWhenDisabled={isSingleEventCard ? false : true}
          >
            {Array(isSingleEventCard ? 1 : 10)
              .fill(0)
              .map((item, index) => {
                return (
                  <SkeletonCard
                    data={{ type: "class" }}
                    key={`skeleton+event+${item}+${index}`}
                  />
                );
              })}
          </Carousel>
        ) : (
          <React.Fragment>
            {cardData.length ? (
              <React.Fragment>
                <Carousel
                  responsive={
                    isSingleEventCard ? responsiveForSingleEvent : responsive
                  }
                  swipeable={true}
                  draggable={true}
                  partialVisible={true}
                  renderArrowsWhenDisabled={isSingleEventCard ? false : true}
                  ref={carousel}
                  // beforeChange={(nextSlide) => {
                  //   const slidePerSlide = carousel.current.state.slidesToShow;
                  //   if (nextSlide === cardData.length - slidePerSlide) {
                  //     paginate.start = paginate.start + paginate.increment;
                  //     handlePagination(
                  //       paginate.start,
                  //       paginate.end,
                  //       dataValue?.slug,
                  //       dataValue?.type
                  //     );
                  //   }
                  // }}
                >
                  {/* {cardData.map((item) => {
                      return <EventCards data={item} key={item.id} />;
                    })} */}
                  {cardData.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <MultiCards
                          eventColor={null}
                          hasIcons={
                            dataValue.cardtype === "garden" ? true : false
                          }
                          datesAvail={
                            dataValue.cardtype === "events" ? true : false
                          }
                          isGarden={
                            dataValue.cardtype === "garden" ? true : false
                          }
                          data={item}
                        />
                      </React.Fragment>
                    );
                  })}
                </Carousel>
              </React.Fragment>
            ) : (
              <NoDataFound>
                <p>{error ? GENERIC_ERROR_MESSAGE : <>No events to show</>}</p>
              </NoDataFound>
            )}
          </React.Fragment>
        )}
      </SliderWrapper>
    </div>
  );
};

export default WidgetSliderWraper;

const SliderWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 0;

  .react-multi-carousel-list {
    padding: 16px 0;
    margin-left: -10px;
    overflow: visible !important;
    position: relative;

    .react-multiple-carousel__arrow {
      position: absolute;
      top: -44px;
      right: 0;
      min-width: unset;
      min-height: unset;
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-color: hsl(182, 100%, 27%);

      &:hover {
        background-color: hsl(182, 100%, 35%);
      }
    }
    .react-multiple-carousel__arrow::before {
      font-size: 14px;
    }

    .react-multiple-carousel__arrow--left {
      right: 40px;
      left: unset;
    }
    .react-multiple-carousel__arrow--left {
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg stroke='hsl(182, 100%, 27%)' fill='hsl(182, 100%, 27%)' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      border: 2px solid #008489;

      &::before {
        opacity: 0;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.7;
        border: 2px solid hsl(0, 0%, 65%);
        background-color: hsl(0, 0%, 65%);
        background-image: url("data:image/svg+xml,%3Csvg stroke='hsl(182, 100%, 100%)' fill='hsl(182, 100%, 100%)' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      }

      &:not(:disabled):hover {
        background-color: #008489;
        background-image: url("data:image/svg+xml,%3Csvg stroke='hsl(182, 100%, 67%)' fill='hsl(182, 100%, 67%)' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      }
    }
    .react-multiple-carousel__arrow--right {
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg stroke='hsl(182, 100%, 27%)' fill='hsl(182, 100%, 27%)' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      border: 2px solid #008489;

      &::before {
        opacity: 0;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.7;
        border: 2px solid hsl(0, 0%, 65%);
        background-color: hsl(0, 0%, 65%);
        background-image: url("data:image/svg+xml,%3Csvg stroke='hsl(182, 100%, 100%)' fill='hsl(182, 100%, 100%)' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      }

      &:not(:disabled):hover {
        background-color: #008489;
        background-image: url("data:image/svg+xml,%3Csvg stroke='hsl(182, 100%, 67%)' fill='hsl(182, 100%, 67%)' stroke-width='0' viewBox='0 0 20 20' aria-hidden='true' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
`;
const NoDataFound = styled.div`
  min-height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #dde6e869;
  border-radius: 6px;
  padding: 5vw;
  text-align: center;
  font-size: 14px;
  & > * {
    max-width: 480px;
    color: #666;
  }
  position: relative;

  p {
    white-space: pre-line;
    line-height: 1.4;
  }

  &::before {
    content: "";
    position: relative;
    width: 2rem;
    height: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg stroke='4a4a4a' fill='4a4a4a' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'%3E%3C/path%3E%3Cpath d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 12px;
  }
`;
