import React from "react";
import "react-multi-carousel/lib/styles.css";
import {
  QUERY_EVENTS_DATA,
  QUERY_GARDENS_DATA,
  QUERY_OFFERINGS_DATA,
  QUERY_SERVICE_DATA,
} from "../../common/api/queries";
import { startFetch } from "../../common/api/hg-hasura";
import { isInThePast } from "../../common/hg_helper";
import WidgetGridWrapper from "../../components/WidgetWrapper/WidgetGridWrapper";
import WidgetSliderWraper from "../../components/WidgetWrapper/WidgetSliderWraper";
import styled from "styled-components";

let paginate = {
  start: 0,
  end: 100,
  increment: 5,
};

const CardGrid = ({ dataValue }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [cardData, setCardData] = React.useState([]);
  let query;
  let isSingleEventCard = dataValue.eventid;
  const handleFetchData = async (start, end, slug, type) => {
    paginate.start = 0;
    setLoading(true);

    if (dataValue?.cardtype === "garden") {
      query = QUERY_GARDENS_DATA();
    } else if (dataValue?.cardtype === "event") {
      query = QUERY_SERVICE_DATA(
        dataValue.listingid,
        Number(dataValue.eventid)
      );
    } else if (dataValue?.cardtype === "offering") {
      query = QUERY_OFFERINGS_DATA(
        dataValue.listingid,
        Number(dataValue.eventid)
      );
    } else {
      query = query = QUERY_EVENTS_DATA(paginate.start, end, slug, "");
    }

    const response = await startFetch(query);
    const data = await response.json();
    if (data.errors) {
      console.log("Error", data);
      setError(true);
      setLoading(false);
      return;
    }
    if (dataValue?.cardtype === "garden") {
      setCardData(data?.data?.listing);
    } else if (dataValue?.cardtype === "event") {
      const past = data?.data?.service_allocations_view.filter((item) => {
        return isInThePast(new Date(item.end_date));
      });
      const upcoming = data?.data?.service_allocations_view.filter((item) => {
        return !isInThePast(new Date(item.start_date));
      });
      if (isSingleEventCard) {
        setCardData(data?.data?.service_allocations_view || []);
      } else {
        setCardData([...upcoming, ...past]);
      }
    } else {
      setCardData(data?.data?.service);
    }
    sendEventToWindow(data);
    setLoading(false);
  };

  function sendEventToWindow(data) {
    let evt = new CustomEvent("widgetLoaded", { detail: data });
    window.dispatchEvent(evt);
  }

  React.useEffect(() => {
    handleFetchData(
      paginate.start,
      paginate.end,
      dataValue?.slug,
      dataValue?.type
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue?.slug, dataValue?.type, dataValue?.cardtype]);
  if (
    !cardData.length &&
    (dataValue.hidenodatastate === "true" || dataValue.hidenodatastate)
  ) {
    return <></>;
  }

  if (dataValue?.layout === "grid") {
    return (
      <>
        {dataValue.title && (
          <TitleWrapper type={dataValue.layout}>{dataValue.title}</TitleWrapper>
        )}
        <WidgetGridWrapper
          loading={loading}
          cardData={cardData}
          error={error}
          dataValue={dataValue}
        />
      </>
    );
  }

  return (
    <>
      {dataValue.title && <TitleWrapper>{dataValue.title}</TitleWrapper>}
      <WidgetSliderWraper
        loading={loading}
        error={error}
        dataValue={dataValue}
        cardData={cardData}
        isSingleEventCard={isSingleEventCard}
      />
    </>
  );
};

export default CardGrid;

const TitleWrapper = styled.h2`
  max-width: 1300px;
  margin-inline: auto;
  margin-bottom: ${(props) => (props.type == "grid" ? "12px" : "-40px")};
  font-size: 24px;
  line-height: 1;
`;
