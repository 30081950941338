import React from "react";
import ReactDOM from "react-dom";
import CardGrid from "./CardGrid";

const renderWidget = (elem, widget) => {
  ReactDOM.render(<React.StrictMode>{widget}</React.StrictMode>, elem);
};

const allInstances = Array.from(document.getElementsByName("hgWidgetFrame"));

allInstances.forEach((instance) => {
  renderWidget(
    instance,
    <CardGrid
      dataValue={instance ? JSON.parse(JSON.stringify(instance?.dataset)) : ""}
    />
  );
});
