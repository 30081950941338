import React, { useEffect } from "react";
import styled from "styled-components";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineDollar, AiFillHeart, AiFillStar } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { FaShoppingBag } from "react-icons/fa";
import { GetAppointmentTypeInfo } from "../../common/api/hg-api";
import { isInThePast } from "../../common/hg_helper";
import { BiArea } from "react-icons/bi";
import { BsFillPeopleFill } from "react-icons/bs";
import { GiFlowerPot } from "react-icons/gi";
import { resolveUrl } from "../../common/hg_helper";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
const { REACT_APP_GARDEN_SRC } = process.env;
const CAMPAIGNS = [
  {
    name: "dates",
    color: "BF5781",
    tooltip: "book a date here",
    icon: <AiFillHeart />,
    image: "",
  },
  {
    name: "work",
    color: "6BC950",
    tooltip: "work from a garden",
    icon: <FaShoppingBag />,
    image: "",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const MultiCards = ({
  data,
  eventColor,
  datesAvail,
  hasIcons,
  isGarden,
  layout,
}) => {
  const [slotDate, setSlotDate] = React.useState(" ");
  const [slotAvail, setSlotAvail] = React.useState(0);

  useEffect(() => {
    const getAppointmentType = async (id) => {
      let res = await GetAppointmentTypeInfo(id);
      setSlotDate(res?.fromDate);
      setSlotAvail(res?.available);
    };
    if (data?.type === "class") {
      getAppointmentType(data?.product_details[0]?.id);
    } else {
      return;
    }
  }, [data?.product_details, data?.type]);

  const getCurrentCamp = (name) => {
    return CAMPAIGNS.filter((camp) => {
      return camp.name === `${name}`;
    });
  };

  const resolveSlug = (url) => {
    const isNativeApp = window.location.host.includes("healinggardens");
    const redirectUrl = REACT_APP_GARDEN_SRC;
    if (isNativeApp) {
      return url;
    } else {
      // Check if redirectUrl already includes the protocol
      if (
        !redirectUrl.startsWith("http://") &&
        !redirectUrl.startsWith("https://")
      ) {
        // If not, concatenate with window.location.protocol
        return window.location.protocol + "//" + redirectUrl + url;
      } else {
        // If it already includes the protocol, simply concatenate without duplicating
        return redirectUrl + url;
      }
    }
  };

  let startsFrom = data?.price || 0;

  if (data.price_list) {
    if (Object.values(data.price_list).length) {
      startsFrom = Math.min(
        ...Object.values(data.price_list)
          .filter((item) => item !== "null")
          .map((item) => {
            const x = JSON.parse(item);
            return x.price;
          })
      );
    }
  }

  if (isGarden)
    return (
      <>
        <MultiCardWrapper eventColor={eventColor} layout={layout}>
          <MultiImage>
            <div className="imageCarousel">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                renderDotsOutside={false}
                responsive={responsive}
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
              >
                {data.images.slice(0, 3).map(({ link, caption }, index) => {
                  return (
                    <div className="imageCarousel--image" key={index + link}>
                      <img
                        src={resolveUrl(link, 2)}
                        alt={caption}
                        draggable={false}
                        loading="lazy"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </MultiImage>
          {(data?.address?.city || data?.address?.state) && (
            <CityAndState>
              <FiMapPin />
              {`${data?.address?.city || ""}, 
                ${data?.address?.state || ""}`}
            </CityAndState>
          )}
          <MultiContent eventColor={eventColor}>
            <div className="text">
              <div className="gardenDetails">
                <h3 className="heading one-line" title={data?.name}>
                  {data?.name || "Sarvodaya Farms & Nursery"}
                </h3>

                {data?.services?.length ? (
                  <p>
                    {`${
                      data?.services?.filter((item) => {
                        return item.type === "1";
                      }).length
                    }
                offering &
                ${
                  data?.services?.filter((item) => {
                    return item.type === "2";
                  }).length
                }  Events`}
                  </p>
                ) : (
                  <p>No events available</p>
                )}
              </div>
            </div>

            <div className="cta">
              <a href={resolveSlug(`/gardens/${data.slug}`)} className="">
                Explore
              </a>
            </div>
          </MultiContent>
          <MultiUnderline>
            <p className="">
              <>
                <span>
                  <span>
                    <BiArea />
                    {`${data?.amenities?.gardenSize || 2000} sq.ft`}
                  </span>
                  <span>
                    <BsFillPeopleFill />
                    {`${data?.amenities?.maxCapacity || 100}`}
                  </span>
                  {data?.reviews_aggregate?.aggregate?.avg?.rating && (
                    <span>
                      <AiFillStar />
                      {`${data?.reviews_aggregate?.aggregate?.avg?.rating}`}
                    </span>
                  )}
                </span>
              </>
            </p>
          </MultiUnderline>
          {hasIcons ? (
            <MultiCardIcons>
              {data?.campaigns?.map((item, index) => {
                const currentCamp = getCurrentCamp(item);
                return (
                  <span
                    key={item + index}
                    style={{ background: `#${currentCamp[0].color}` }}
                    title={currentCamp[0].tooltip}
                  >
                    {currentCamp[0].icon}
                  </span>
                );
              })}
            </MultiCardIcons>
          ) : (
            ""
          )}
        </MultiCardWrapper>
      </>
    );
  return (
    <MultiCardWrapper
      eventColor={eventColor}
      layout={layout}
      isPastEvent={isInThePast(new Date(data.end_date))}
    >
      <MultiImage>
        <img
          src={
            data?.images[0]?.link
              ? resolveUrl(data?.images[0]?.link, 2)
              : "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
          }
          loading="lazy"
          alt=""
          className="image-2"
          draggable={false}
        />
      </MultiImage>
      {(data?.listing?.address?.city || data?.listing?.address?.state) && (
        <CityAndState>
          <FiMapPin />
          {`${data?.listing?.address?.city || ""}, 
                ${data?.listing?.address?.state || ""}`}
        </CityAndState>
      )}
      <MultiContent eventColor={eventColor}>
        <div className="text">
          <>
            <h3 className="heading one-line" title={data?.name}>
              {data?.name || "Date in a Garden"}
            </h3>
            <p className="one-line">
              <GiFlowerPot />
              {data?.listing_name
                ? data?.listing_name
                : data?.listing?.name || ""}
            </p>
            <p className="one-line">
              <AiOutlineDollar />
              Starts from :
              <strong>
                {startsFrom === Infinity ? " N/A" : `$${startsFrom}`}
              </strong>
            </p>
          </>
        </div>

        <div className="cta">
          <a
            href={resolveSlug(`/service/${data.id}/book`)}
            className="booknow-btn"
          >
            Book Now
          </a>
        </div>
      </MultiContent>
      {/* {data?.type === "class" && ( */}
      {data.hg_service_type >= 2 && (
        <MultiUnderline>
          <p className="">
            {data.booking_limit ? (
              <>
                <span>
                  <span>
                    <FaRegCalendarAlt />
                    {data?.start_date || ""}
                  </span>
                  {/* <span>
                    <strong> {data.booking_limit} Left </strong>
                  </span> */}
                </span>
              </>
            ) : (
              <>
                <span>
                  <span
                    style={{ background: "hsl(182,20%,75%)", color: "#fff" }}
                  >
                    <FaRegCalendarAlt />
                    Not available
                  </span>
                  <span
                    style={{ background: "hsl(182,20%,75%)", color: "#fff" }}
                  >
                    <strong> Sold out </strong>
                  </span>
                </span>
              </>
            )}
          </p>
        </MultiUnderline>
      )}
      {/* )} */}
      {hasIcons ? (
        <MultiCardIcons>
          <span style={{ background: "#BF5680" }}>
            <AiFillHeart />
          </span>
          <span style={{ background: "#6BC950" }}>
            <FaShoppingBag />
          </span>
        </MultiCardIcons>
      ) : (
        ""
      )}
    </MultiCardWrapper>
  );
};

export default MultiCards;

const MultiCardWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: ${(props) => (props.layout === "grid" ? "0" : "0 10px")};
  border-radius: 6px;
  box-shadow: 6px 6px 20px 0 rgb(145 128 128 / 18%);
  overflow: hidden;
  position: relative;
  background: ${(props) => (props.eventColor ? props.eventColor : "#fff")};
  color: ${(props) => (props.eventColor ? "#fff" : "#333")};
  h3 {
    color: ${(props) => (props.eventColor ? "#fff" : "#333")};
  }

  pointer-events: ${(props) => (props.isPastEvent ? "none" : "all")};

  & > div {
    filter: ${(props) => (props.isPastEvent ? "grayscale(0.8)" : "none")};
    opacity: ${(props) => (props.isPastEvent ? "0.7" : "1")};
  }
  &::after {
    content: "Completed";
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 3;
    background-color: rgba(255, 120, 117, 1);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 200px;
    padding: 8px 12px;
    font-size: 12px;
    text-align: center;
    transform-origin: center;
    backdrop-filter: blur(4px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: ${(props) => (props.isPastEvent ? "block" : "none")};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
  }

  img {
    max-width: 100%;
    display: block;
  }
`;

const CityAndState = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 200px;
  padding: 8px;
  font-size: 12px;
  backdrop-filter: blur(4px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

  svg {
    display: block;
    margin-right: 8px;
  }
`;
const MultiImage = styled.div`
  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .imageCarousel {
    min-height: 240px;
    .react-multi-carousel-list.container {
      padding: 0;

      .react-multiple-carousel__arrow {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        min-width: unset;
        min-height: unset;
        cursor: pointer;
        width: 36px;
        height: 36px;
        background-color: transparent;

        &:hover {
          background-color: hsl(182, 100%, 35%);
        }
      }
      .react-multiple-carousel__arrow::before {
        font-size: 20px;
      }

      .react-multiple-carousel__arrow--left {
        right: unset;
        left: 16px;
      }
      .react-multiple-carousel__arrow--right {
      }

      .react-multiple-carousel__arrow--left[disabled] {
        cursor: not-allowed;
        opacity: 0.7;
        background: none;
        border: 2px solid #008489;
        color: #008489;
        &::before {
          color: #008489;
        }
      }
      .react-multiple-carousel__arrow--right[disabled] {
        opacity: 0.5;
      }

      .react-multi-carousel-dot-list {
        padding-bottom: 10px;
        .react-multi-carousel-dot {
          button {
            width: 8px;
            height: 8px;
            border: none;
          }
        }
        .react-multi-carousel-dot.react-multi-carousel-dot--active {
          button {
            background-color: hsl(182, 100%, 27%);
          }
        }
      }
    }
  }

  .imageCarousel--image {
    height: 240px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
const MultiContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;

  .one-line {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .gardenDetails {
    h3 {
      font-size: 14px;
      margin-bottom: 4px;
      color: #2c2c2c;
      font-weight: 500;
    }
    a {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: hsl(182deg 100% 27%);
      text-decoration: none;

      svg {
        margin-left: 4px;
      }
    }
    p {
      font-size: 12.6px;
    }
  }
  & > .text {
    padding-right: 12px;

    & > .heading {
      font-size: 14px;
      margin-bottom: 6px;
    }

    & > p {
      font-size: 12.6px;

      svg {
        width: 12.6px;
        height: 12.6px;
        display: inline-block;
        margin-right: 6px;
        transform: translateY(2px);
      }
    }
    & > p:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
  & > .cta {
    & > a {
      display: inline-block;
      padding: 10px 16px;
      border-radius: 5rem;
      background-color: ${(props) => (props.eventColor ? "#fff" : "#008489")};
      color: ${(props) =>
        props.eventColor ? "#008489 !important" : "#fff !important"};
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap;
    }
  }
`;

const MultiUnderline = styled.div`
  border-top: ${(props) =>
    props.eventColor
      ? "1px solid hsla(0, 0%, 95%,0.5)"
      : "1px solid hsl(182, 27%, 85%)"};
  font-size: 12.6px;
  padding: 12px 12px 12px 12px;
  & > p {
    display: flex;
    align-items: center;
  }

  & > p > span {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1300px) {
      font-size: 12px;
    }
    span {
      background: ${(props) =>
        props.eventColor ? "hsla(0, 0%, 95%,0.5)" : "hsl(0, 0%, 95%)"};
      padding: 4px 8px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      svg {
        margin-right: 6px;
        margin-bottom: -1px;
        display: inline-block;
      }
    }
    .availCount {
      strong {
        color: ${(props) => (props.eventColor ? "#fff" : " #008489")};
      }
    }
  }
`;
const MultiCardIcons = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;

  span {
    background: #fff;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 6px;
    border-radius: 20px;

    svg {
      width: 20px;
      height: 20px;
      display: block;
      fill: #fff;
    }
  }

  span:not(:last-of-type) {
    margin-right: 6px;
  }
`;
