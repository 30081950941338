import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonCard = ({ data }) => {
  return (
    <EventCardWrapper>
      <EventImage></EventImage>
      <EventContent>
        <div className="text">
          <div
            className="skeletonText skeletonTextFull"
            style={{ animationDelay: "100ms" }}
          ></div>
          <div
            className="skeletonText"
            style={{ animationDelay: "150ms" }}
          ></div>
          <div
            className="skeletonText"
            style={{ animationDelay: "200ms" }}
          ></div>
        </div>
        <div className="cta">
          <div
            className="skeletonCta"
            style={{ animationDelay: "250ms" }}
          ></div>
        </div>
      </EventContent>
      {data?.type === "class" && (
        <EventDatesAvail>
          <div
            className="skeletonText skeletonTextFull"
            style={{ animationDelay: "300ms" }}
          ></div>
        </EventDatesAvail>
      )}
    </EventCardWrapper>
  );
};

export default SkeletonCard;

const breatheAnimation = keyframes`
 0% { background:hsl(182,20%,75%) }
 100% { background:hsl(182,25%,92%) }
`;

const EventCardWrapper = styled.div`
  height: 100%;
  margin: 0 10px;
  border-radius: 6px;
  box-shadow: 6px 6px 20px 0 rgb(145 128 128 / 18%);
  overflow: hidden;
  max-width: 100%;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
  }

  .skeletonText {
    opacity: 0.6;
    width: 100%;
    height: 12px;
    margin-bottom: 8px;
    border-radius: 4px;
    background: hsl(182, 20%, 70%);
    animation-name: ${breatheAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }
  .skeletonText:last-of-type {
    margin-bottom: 0px;
    width: 80%;
  }
  .skeletonText.skeletonTextFull {
    width: 100%;
    height: 18px;
  }
  .skeletonCta {
    opacity: 0.6;
    height: 38px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5rem;
    background: hsl(182, 20%, 70%);
    animation-name: ${breatheAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }
`;
const EventImage = styled.div`
  height: 200px;
  width: 100%;
  opacity: 0.6;
  background: hsl(182, 20%, 70%);
  animation-name: ${breatheAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
`;
const EventContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  & > .text {
    padding-right: 12px;
    width: 100%;
  }
  & > .cta {
    width: 30%;
  }
`;

const EventDatesAvail = styled.div`
  padding: 6px 12px 20px 12px;
`;
