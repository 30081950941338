const { REACT_APP_HG_API } = process.env;

export const GetAppointmentTypeInfo = async (id) => {
  try {
    const response = await fetch(
      `${REACT_APP_HG_API}/ProductApi/v1/appointmenttypes?id=${id}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    switch (response.status) {
      case 200:
        return await response.json();
      case 204:
      case 400:
      default:
        return null;
    }
  } catch (error) {
    console.log("Error while retrieving the appointment information", error);
  }
};
