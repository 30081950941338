import React from "react";
import SkeletonCard from "../Cards/SkeletonCard";
import { GENERIC_ERROR_MESSAGE, isInThePast } from "../../common/hg_helper";
import MultiCards from "../Cards/MultiCard";
import styled from "styled-components";

const WidgetGridWrapper = ({ dataValue, cardData, loading, error }) => {
  function onLocationChange(state) {
    let evt = new CustomEvent("locationchanged", { detail: state });
    window.dispatchEvent(evt);
  }
  return (
    <>
      <GridWrapper>
        {loading ? (
          <>
            {Array(10)
              .fill(0)
              .map((item, index) => {
                return (
                  <SkeletonCard
                    data={{ type: "class" }}
                    key={`skeleton+card+${item}+${index}`}
                  />
                );
              })}
          </>
        ) : (
          <>
            {cardData.length ? (
              <>
                {cardData.map((item) => {
                  let location;
                  if (dataValue.cardtype === "garden") {
                    location = item?.position;
                  } else {
                    location = item?.listing?.position;
                  }
                  return (
                    <div
                      key={item.id + item.name + "div"}
                      onClick={() => {
                        if (
                          dataValue.cardtype !== "garden" &&
                          isInThePast(new Date(item.end_date))
                        ) {
                          return;
                        }
                        onLocationChange(location);
                      }}
                      style={{
                        cursor: `${
                          isInThePast(new Date(item.end_date))
                            ? "not-allowed"
                            : "pointer"
                        }`,
                      }}
                    >
                      <MultiCards
                        eventColor={null}
                        hasIcons={
                          dataValue.cardtype === "garden" ? true : false
                        }
                        datesAvail={
                          dataValue.cardtype === "event" ? true : false
                        }
                        isGarden={
                          dataValue.cardtype === "garden" ? true : false
                        }
                        data={item}
                        layout={dataValue.layout}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <NoDataFound>
                <p>{error ? GENERIC_ERROR_MESSAGE : "No Data Found"}</p>
              </NoDataFound>
            )}
          </>
        )}
      </GridWrapper>
    </>
  );
};

export default WidgetGridWrapper;

const GridWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 0;
  font-family: inherit;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 32px;

  & > div {
    margin: 0;
  }
`;
const NoDataFound = styled.div`
  min-height: 320px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #dde6e869;
  border-radius: 6px;
  padding: 5vw;
  text-align: center;
  font-size: 14px;
  & > * {
    max-width: 480px;
    color: #666;
  }
  position: relative;

  p {
    white-space: pre-line;
    line-height: 1.4;
  }

  &::before {
    content: "";
    position: relative;
    width: 2rem;
    height: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg stroke='4a4a4a' fill='4a4a4a' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'%3E%3C/path%3E%3Cpath d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 12px;
  }
`;
